import React from 'react';

const JobTypeService = (props) => {
    
  const {goStart, taska1, taska2, taska3, goBackLevel}=props
    return (
      <>
        <div className="flex flex-col">
          <p className="m-2">Виберіть, будь ласка, варіант опису проблеми</p>
          <ul>
            <button onClick={taska1}  className="btn-servise">
            1. Проблема зі зв'язком  
              </button>
           
            <button onClick={taska2} className="btn-servise">
            2. Tamper (Alert Irruption)
              </button>

            <button onClick={taska3} className="btn-servise">
            3. Не проводить оплати
              </button>

            <button onClick={goStart} className="btn-raif">
                {/* <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                  <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                </svg> 
                </span> */}
              <span>🏠  На початок</span>
              
            </button>

            <button onClick={goBackLevel} className="btn-servise">
              👈 Крок назад
            </button>

    </ul>
          <h3> </h3>
        </div>
       
    </>
  )
}
export default JobTypeService;