import React, { useEffect } from "react";
import axios from "axios";

export default function Order(props) {
  const {
    data,
    goStart,
    goBackLevel,
    orderData,
    setOrderData,
    loading,
    setLoading,
    error,
    setError,
    jobType,
    orderTask,
  } = props;

  const options = {
    modelName: "CreateOrder",
    serial: `${data.serial}`,
    bankID: `${data.bankID}`,
    jobType: jobType,
    orderTask: orderTask,
    rollCount: data.rollCount,
  };

  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    await axios
      .post("/order", options)
      .then((response) => {
        if ("success" in response.data) {
          let resData = response.data;

          setOrderData(resData);

          console.log(resData);
        } else {
          setLoading(false);
          setError(true);
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        console.error("Помилка при завантажені : ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading)
    return (
      <div>
        <h1>Формую заявку .... </h1>
      </div>
    );
  if (error)
    return (
      <div>
        <h1 className="text-2xl font-extrabold text-center text-base text-red-600">
          Сталася помилка
        </h1>
        <div>
          вибачте, але{" "}
          <b> Для цього терміналу вже є аналогічне замовлення в роботі.</b>
        </div>
        <div className="">
          {/* Можливо, ви некорректно написали номер, або ж вам потрібно звернутися
          за номером техпідтримки. */}
        </div>

        <button className="btn-red" onClick={goBackLevel}>
          Крок назад
        </button>

      </div>
    );

  console.log(orderData);

  const orders = () => {
    if (orderData.error === "order already exists") 
      return orderData.ordersNum;
      else return orderData.ordersNum;
  };
  return (
    <>
      {/*
error: ""
orderNumber: "ОЩУК-211111-100"
plannedCloseDateTime: "11.11.2021 18:37:16"
status: "В работе"
success: "True"
Дякую, Alex, ваше замовлення прийняте.

  */}

  {/* Вже є створенно замовлення */}
      <div className="m-2">
        <div className="italic tracking-tight text-center text-gray-900 border-2 border-green-600 rounded-full">
          <p className="font-bold"></p>
        </div>

        <section className="space-y-2 text-center divide-y-2 divide-grey-600 divide-solid">
          <h2 className="content-center m-4 text-2xl font-bold text-green-800">
            {orderData.error !== ""
              ? "Для цього терміналу вже є аналогічне замовлення в роботі."
              : " ЗАЯВКА СТВОРЕНА"}
          </h2>
          {/* <div></div> */}
          
          <table className="border-separate border-collapse border">
            <thead>
              <tr className="uppercase">
                <th className="border border-slate-100 text-sm">Номер замовлення</th>
                <th className="border border-slate-100 text-sm">Дата виконання</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-slate-100">
                  {orderData.error === "order already exists"
                  ? orderData.ordersNum
                  : orderData.ordersNum}
                </td>
                <td className="border border-slate-100">
                  {orderData.error === "order already exists"
                    ? orderData.plannedCloseDateTime
                    : orderData.plannedCloseDateTime}
                </td>
              </tr>
            </tbody>
          </table>
          <table></table>

        </section>
        <button className="btn-red" onClick={goStart}>
          На початок
        </button>
      </div>
    </>
  );
}
