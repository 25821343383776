import React from "react";

const Hello = () => {

return(
  
      <div className="w-full bg-zinc-100 p-4 font-normal text-gray-600 bg-white text-md">
        <p>
          <span className="font-bold">Вітаю Вас,</span>
          <br />
          У сервіс чат-боті для підтримки користувачів POS-терміналів від Райфа. Якщо у Вас виникли проблеми по роботі з обладнанням, завантажте інструкцію, та спробуйте полагодити його самостійно 👇
        </p>
        <div>
        </div>
        <div className="w-full m-2">
          <a target='_blank' rel = "noopener noreferrer" href="/instruction-kasir-printec.pdf">
            <img
              src="Frame-2.png"
              alt="Printecbot"
              className="mx-10 h-16"
            />
          </a>
        </div>
        <p className="font-bold text-red-600 text-lg mt-3">За допомогою даного сервісу можна:</p>
        <ul className="list-decimal pl-5">
          <li className="font-bold">Подавати заявки на доставку термострічки</li>
          <li className="font-bold">Повідомити про проблеми зі зв'язком</li>
          <li className="font-bold">Повідомити про проблеми з оплатою</li>
          <li className="font-bold">Повідомити про помилки «Tamper» / «Alert Irruption»</li>
        </ul> 
        {/* <p className="mt-3 font-light">Нижче можливо завантажити інструкцію користування терміналом.👇</p> */}

      </div>
)
}
export default Hello;
