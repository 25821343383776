import React from "react";
import Home from "./screens/Home";
import Controler from "./prod/Controler";
import "./index.css";

const App = () => {
  return (
    <>
    {/* max-h-screen  */}
      <div className="flex flex-col justify-center py-6 bg-gray-50 sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">

        
          <div className="absolute inset-0 transform -skew-y-6 shadow-lg bg-gradient-to-r from-green-200 to-blue-300 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl">
            <div className="absolute inset-0 bg-gradient-to-r from-green-300 to-[#B1FFF3] shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
            </div>
          </div>

          {/* Navigation with Logos */}
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">

              <Home />           
                


              <Controler />


            </div>
        </div>
      </div>


    
    </>
  );
};

export default App;