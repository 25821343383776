import React from "react";
import Hello from "../ui/Hello";

export default function Header(props) {
  const { goNextLevel } = props;

  return (
    <>
         <Hello />
              <button onClick={goNextLevel} 
                className="btn-raif">
                🏁 Райф розпочати!
              </button>
      
    </>
  );
}
