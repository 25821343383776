import React, { useEffect } from "react";

export default function Forma(props) {
  //пропси
  const { data, handleChange, goNextLevel } = props;
  
  useEffect(() => {
    document.title = `Авторизація`;  
  }, []);

  return (
    <>
      <form>
        <p>
          Для ідентифікації відправте мені, будь ласка, серійний номер Вашого
          терміналу.
        </p>
        <label className="label">Cерійний номер</label>
        <input
          onChange={handleChange}
          className="input"
          type="text"
          placeholder="Введіть серійний номер терміналу"
          name="serial"
          value={data.serial}
        />

        {/* info section */}
        <div className="mt-3">
          <p className="p-1 text-sm italic text-gray-600 dark:text-gray-200">
          <span className="font-bold  
          text-red-500 dark:text-blue-400">
            Важливо!</span> Cерійний номер введіть без «-» та прогалин (серійний номер знаходиться
            позаду на кришці або під кришкою терміналу, після напису s/n)
          </p>
        </div>

        <button
          onClick={goNextLevel}
          disabled={!data.serial}
          className="btn-raif mt-2
          disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer"
          id="authorization"
          // value={data.bankID}
        >
          Ідентифікуватися
        </button>
      </form>
    </>
  );
}
