import React, {useEffect} from "react";
import Taska1 from "../ui/Taska1";

const HelpMsg = (props) => {
  const {golvlOrder,  orderTask, goBackLevel} = props;

  useEffect(() => {
    document.title = `Нічого не допомогло`;  
  }, []);

  return (
    <>

    <Taska1 orderTask={orderTask}/>

      <div>
        <button className="btn btn-red" onClick={golvlOrder}>
          Нічого не допомогло
        </button>

        <button onClick={goBackLevel} className="btn-servise">
        👈 Крок назад
        </button>
      </div>
    </>
  );
};
export default HelpMsg;
