import React, { useEffect } from "react";

const JobTypePaper = (props) => {
  useEffect(() => {
    document.title = `Замовлення бумаги`;  
  }, []);

  const { data, golvlOrder, handleChange, goBackLevel } = props;

  const limitMax = 20
  
  const rr = data.rollCount <= limitMax ? data.rollCount :
             data.rollCount > limitMax ? limitMax : "Введіть кількість рулонів до 20шт" 
  return (
    <>
      <div className="flex flex-col">
        <p className="m-2">Введіть цифрами необхідну кількість рулонів</p>
        

        <div></div>
        <input
          className="text-center m-2 select border-inherit border-dotted border-2"
        
          placeholder="Введіть кількість рулонів"
          onChange={handleChange}
          inputmode="numeric"
          type="number"
          name="rollCount"
          value={rr}
          
        >
           
        </input>
        <label className="text-sm m-2">Максимальна кількість рулонів бумаги 20 шт.</label>

        <button className="btn btn-raif" onClick={golvlOrder}>
          Сформувати
        </button>

        {/* <p>Кількість рулонів на доставку: <span>{rr} </span>  </p> */}
      </div>
      <button onClick={goBackLevel} className="btn-servise">
                👈Крок назад
      </button>
    </>
  );
};
export default JobTypePaper;
