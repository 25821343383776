import React, { useEffect } from "react";

const InitTerminal = (props) => {

  useEffect(() => {
    document.title = `Виправлення помилок`;  
  }, []);

  const {goBackLevel} = props;

  return (
    <>
    <section className="text-gray-600 body-font">
      <div className="container flex flex-wrap px-5 py-4 mx-auto">
        {/* крок 1 */}
        <div className="relative flex flex-grow pt-0 pb-0 mt-4 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            1
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Виконати закриття дня
              </h2>
              <p className="leading-relaxed">
              Виконати закриття дня через відповідний пункт меню «Завершення дня» та натиснути ОК.
              </p>
            </div>
          </div>
        </div>
        {/* крок 2 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            2
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h3 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Ввести пароль касира
              </h3>
              <p className="leading-relaxed">Ввести пароль касира, який було надано при встановленні терміналу.</p>
            </div>
          </div>
        </div>
        {/* крок 3 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            3
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Роздрукувати чек
              </h2>
              <p className="leading-relaxed">Роздрукувати чек про успішне закриття дня.</p>
            </div>
          </div>
        </div>

        {/* крок 4 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            4
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Оновити через пункт меню «Сервіс»
              </h2>
              <p className="leading-relaxed">Здійснити завантаження оновлення через пункт меню «Сервіс», та натиснути ОК.</p>
            </div>
          </div>
        </div>

        {/* крок 5 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            5
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h3 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Обрати пункт меню «Завантаження»
              </h3>
              <p className="leading-relaxed">У меню обрати пункт меню «Завантаження» та натиснути ОК.</p>
            </div>
          </div>
        </div>

        {/* крок 6 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            6
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Ввести пароль касира та зачекати
              </h2>
              <p className="leading-relaxed">Ввести пароль касира та зачекати, поки термінал виконає завантаження, завершенням якого буде автоматичне перезавантаження POS-терміналу.</p>
            </div>
          </div>
        </div>

        {/* крок 7 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            7
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
              Перевірте коректність виконаних змін.
              </h2>
              <p className="leading-relaxed">Кінець</p>
            </div>
          </div>
        </div>

      </div>
    </section>

      <div>

      {/* Кнопка назад */}
        <button onClick={goBackLevel} className="btn-servise">
        👈 Крок назад
        </button>
      </div>
    </>
  );
};

export default InitTerminal;
