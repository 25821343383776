import React, { useState, useEffect } from "react";
import axios from "axios";


export default function UpdateData(props) {
  
  const {
    data,
    // handleChange,
    goNextLevel,
    goStart,
    info,
    // updateInfo,
    setUpdateInfo,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const name = data.locationContactName || info.locationAddress.locationContactName
  const phone = data.locationContactPhone || info.locationAddress.locationContactPhone
  
  console.log(info.locationAddress.locationContactName);
  console.log(data.locationContactName);
  
  const options = {
    serial: `${data.serial}`,
    bankID: `${data.bankID}`,
    locationContactName: `${name}`,
    locationContactPhone: `${phone}`,
  };
  console.log(options);

  useEffect(() => {
    getData();
  }, []);
    async function getData() {
      await axios
        .post("/update",options)
        .then((response) => {
          if ('success' in response.data) {
            let resData = response.data;
            console.log("success" in response.data);
            setUpdateInfo(resData);
             console.log(resData);
          } else {
            setLoading(false);
            setError(true);
            throw new Error(response.statusText);
          }
        })
        .catch((error) => {
          console.error("Помилка при завантажені : ", error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

  if (loading)
    return (
      <div>
        <h1>оновлення ....</h1>
      </div>
    );
  if (error)
    return (
      <div>
        <h1 className="text-2xl font-extrabold text-center text-red-600">
          Сталася помилка
        </h1>
        <div>
          вибачте, але <b>термінала з таким ID та серійним номером немає в базі</b>
        </div>
        <div className="">
          Можливо, ви некорректно написали номер, або ж вам потрібно звернутися
          за номером техпідтримки.
        </div>
        <button className="btn-red" onClick={goStart}>
          На початок
        </button>
      </div>
    );

  return (
    <>
      <div className="m-2">
        <div className="italic tracking-tight text-center text-gray-900 border-2 border-green-600 rounded-full">
          <p className="font-bold">Облікові дані за терміналом :
          </p>
        </div>
        <p><span>серійний номер:  {data.serial}</span></p>
        <p><span>Bank ID:         {data.bankID}</span></p>
        <p><span>модель:          {info.terminal.model}</span></p>

        <section className="space-y-2 text-center divide-y-2 divide-grey-600 divide-solid">
          <h1 className="content-center m-4 text-2xl font-bold text-green-800">
        {info.location.locationName}
          </h1>

      
          <p className="text-left">
           <span className="italic">Контактна особа:</span> 
            <span className="ml-2">
              <b>
              {name}
              </b>
            </span>
          </p>
          
          <p className="text-left">
           <span className="italic">Номер контактної особи:</span> 
            <span className="ml-2">
              <b>
                {phone}
              </b>
            </span>
          </p>

          <p className="text-left">
           <span className="italic">Банк клієнта:</span> 
            <span className="ml-2">
              <b>{info.client.clientName}</b>
            </span>
          </p>
 
        

        </section>
      </div>

      <div className="space-y-2">
        <button
          onClick={goNextLevel}
          className="btn-red"
        >
          Вперед
        </button>
      </div>

    </>
  );
}
