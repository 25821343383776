import React from "react";

export default function Home() {
  
  return (
    <div>
      <nav class="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-100">
        <div class="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" class="flex items-center">
              <img src="./logo192.png" class="mr-3 h-14 sm:h-14" alt="Printec Logo" />
          </a>
          <a href="/" class="flex items-center bg-[#FFFF00]">
              <img src="./RBI-logo.png" class="mr-3 h-14 sm:h-14" alt="Raif Logo" />
          </a>
        </div>
      </nav>
    </div>
  )
}