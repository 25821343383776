import React, { useState } from "react";
import Start from "./Start";
import InfoTerminal from "./InfoTerminal";
import Forma from "./Forma";
import UpdateData from "./UpdateData";
import CreateOrder from "./CreateOrder";
import Order from "./Order";
import JobTypePaper from "./JobTypePaper";
import JobTypeService from "./JobTypeService";
import HelpMsg from "./HelpMsg";
import EditTerminalInfo from "./EditTerminalInfo";
import InitTerminal from "./InitTerminal";



export default function Controler() {
  //визначаємо стартовий показник стану дії
  const [level, setLevel] = useState(1);

  //i
  const [info, setInfo] = useState([]);
  const [updateInfo, setUpdateInfo] = useState([]);
  const [orderData, setOrderData] = useState();
  const [doubling, setDoubling] = useState();
  //функція стану даних з inputs
  const [inputData, setInputData] = useState({
    serial: "",
    bankID: "RBA"
  });

  const [jobType, setJobType] = useState();
  const [orderTask, setOrderTask] = useState();
  const [initTerminal, setInitTerminal] = useState();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //функція
  function handleSubmit(e) {
    e.preventDefault();
    console.log("Заявка створена.");
  }

  // функція запису зміни даних в inputs
  const handleChange = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
  };

  //функція
  const lvlStart = 1;
  const lvlForma = 2;
  const lvlInfoTerminal = 3;
  const lvlEditTerminalInfo =4;
  const lvlUpdateData = 5;
  const lvlCreateOrder = 6;
  const lvlJobTypeService = 7;
  const lvlHelpMsg = 8;
  const lvlJobTypePaper = 9;
  const lvlOrder = 10;
  const lvlInitTerminal = 11;

   //функція переходу на рівень визначення jobType
   const golvlCreateOrder = () => {
    setLevel(lvlCreateOrder);
  };

  //функція переходу на рівень формування заявки
  const golvlOrder = () => {
    setLevel(lvlOrder);
  };

  //
    //функція переходу на рівень формування заявки
    const golvlInfoTerminal = () => {
      setLevel(lvlInfoTerminal);
    };
  //EditTerminalInfo
    //функція переходу на рівень оновлення даних
    const golvlEditTerminalInfo = () => {
      setLevel(lvlEditTerminalInfo);
    };
  //функція переходу на рівень оновлення даних
  const golvlUpdateData = () => {
    setLevel(lvlUpdateData);
  };
  //функція переходу на наступний рівень дії
  const goNextLevel = () => {
    setLevel(level + 1);
  };
  //функція переходу на при виборі замов паперу
  const goPaper = () => {
    setJobType("action_get_papers");
    setLevel(lvlJobTypePaper);
  };

  //функція переходу на рівень jobType Обслуговування
  const goService = () => {
    setJobType("action_support");
    setLevel(lvlJobTypeService);
  };

  //функція переходу на рівень Initialize Terminal
  const goInitTerminal = () => {
    setInitTerminal("Ініціалізація терміналу");
    setLevel(lvlInitTerminal);
  }

  //функція переходу на при виборі "Проблема зі зв'язком"
  const taska1 = () => {
    setOrderTask("Проблема зі зв'язком");
    setLevel(lvlHelpMsg);
  };
  //функція переходу на при виборі "Tampered Device" або "Alert Irruption"
  const taska2 = () => {
    setOrderTask("Tamper (Alert Irruption)");
    setLevel(lvlHelpMsg);
  };
  //функція переходу на при виборі "Не проводить оплати"
  const taska3 = () => {
    setOrderTask("Не проводить оплати");
    setLevel(lvlHelpMsg);
  };

  //функція переходу на попередній рівень дії
  const goBackLevel = () => {
    setLevel(lvlCreateOrder);
  };
  //функція переходу на початок
  const goStart = () => {
    setLevel(lvlStart);
  };

  //step by step
  switch (level) {
    // Start
    case 1:
      return <Start goNextLevel={goNextLevel} />;
    // Forma
    case 2:
      return (
        <Forma
          data={inputData}
          handleChange={handleChange}
          goNextLevel={goNextLevel}
        />
      );
    // InfoTerminal
    case 3:
      return (
        <InfoTerminal
          data={inputData}
          handleChange={handleChange}
          golvlCreateOrder={golvlCreateOrder}
          goStart={goStart}
          info={info}
          setInfo={setInfo}
          golvlUpdateData={golvlUpdateData}
          golvlEditTerminalInfo={golvlEditTerminalInfo}
        />
      );
    // EditTerminalInfo
    case 4:
      return (
        <EditTerminalInfo
          data={inputData}
          handleChange={handleChange}
          goNextLevel={goNextLevel}
          info={info}
          updateInfo={updateInfo}
          setUpdateInfo={setUpdateInfo}
          golvlUpdateData={golvlUpdateData}
         
        />
      );
    // UpdateData
    case 5:
      return (
        <UpdateData
          data={inputData}
          handleChange={handleChange}
          goNextLevel={goNextLevel}
          info={info}
          updateInfo={updateInfo}
          setUpdateInfo={setUpdateInfo}
        
        />
      );
    // CreateOrder 
    case 6:
      return (
        <CreateOrder
          // data={inputData}
          // info={info}
          // setInfo={setInfo}
          handleChange={handleChange}
          orderTask={orderTask}
          goStart={goStart}
          goPaper={goPaper}
          goService={goService}
          goInitTerminal={goInitTerminal}
          golvlInfoTerminal={golvlInfoTerminal}
          goBackLevel={goBackLevel}
        />
      );
    // JobTypeService 
    case 7:
      return (
        <JobTypeService
          data={inputData}
          handleChange={handleChange}
          jobType={jobType}
          golvlOrder={golvlOrder}
          orderTask={orderTask}
          setOrderTask={setOrderTask}
          goStart={goStart}
          taska1={taska1}
          taska2={taska2}
          taska3={taska3}
          goBackLevel={goBackLevel}
         
        />
      );
    // HelpMsg
    case 8:
      return (
        <HelpMsg
          data={inputData}
          handleChange={handleChange}
          jobType={jobType}
          golvlOrder={golvlOrder}
          orderTask={orderTask}
          setOrderTask={setOrderTask}
          goStart={goStart}
          taska1={taska1}
          taska2={taska2}
          taska3={taska3}
          goBackLevel={goBackLevel}
        />
      );
    // JobTypePaper
    case 9:
      return (
        <JobTypePaper
          data={inputData}
          handleChange={handleChange}
          jobType={jobType}
          golvlOrder={golvlOrder}
          goBackLevel={goBackLevel}
        />
      );
    // Order
    case 10:
      return (
        <Order
          data={inputData}
          handleChange={handleChange}
          goNextLevel={goNextLevel}
          goStart={goStart}
          orderData={orderData}
          setOrderData={setOrderData}
          loading={loading}
          setLoading={setLoading}
          error={error}
          setError={setError}
          jobType={jobType}
          updateInfo={updateInfo}
          doubling={doubling}
          setDoubling={setDoubling}
          orderTask={orderTask}
          setOrderTask={setOrderTask}
        />
      );

      case 11:
        return (
          <InitTerminal
            data={inputData}
            handleChange={handleChange}
            jobType={initTerminal}
            golvlOrder={goInitTerminal}
            orderTask={initTerminal}
            setOrderTask={setInitTerminal}
            goStart={goStart}
            goBackLevel={goBackLevel}
          />
        );
    // default
    default:
      return (
        <Start
          goNextLevel={goNextLevel}
          handleChange={handleChange}
          data={inputData}
        />
      );
  }
}
