import React from "react";

const Taska1 = (props) => {
  let orderTask = props.orderTask;
  
  // Taska1 - Проблема зі зв'язком
  if (orderTask === "Проблема зі зв'язком") 
    return (
      <section className="text-gray-600 body-font">
        <div className="container flex flex-wrap px-5 py-4 mx-auto">
         
          {/* крок 1 */}
          <div className="relative flex flex-grow pt-0 pb-0 mt-4 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              1
            </div>
            <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
              <div className="">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Перезавантажити термінал
                </h2>
                <p className="leading-relaxed">
                  для стаціонарного терміналу – від’єднати кабель живлення терміналу, для переносного залежно від моделі: Castles – натиснути та утримувати жовту та маленьку точку. Для Verifone – натиснути та утримувати червону кнопку, попередньо відключивши від зарядного пристрою.
                </p>
              </div>
            </div>
          </div>
          
          {/* крок 2 */}
          <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              2
            </div>
            <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
              <div className="mt-6">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Після перезавантаження зробити тестову транзакцію.
                </h2>
                <p className="leading-relaxed"></p>
              </div>
            </div>
          </div>
        
          {/* крок 3 */}
          <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              3
            </div>
            <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
              <div className="mt-6">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Якщо пункти вище не допомогли, натисніть внизу кнопку "Нічого
                  не допомогло".
                </h2>
                <p className="leading-relaxed"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  
  // Task2 - Tamper (Alert Irruption)
  if (orderTask === "Tamper (Alert Irruption)") 
    return (
      <section className="text-gray-600 body-font">
        <div className="container flex flex-wrap px-5 py-4 mx-auto">
        
          {/* крок 1 */}
          <div className="relative flex flex-grow pt-0 pb-0 mt-4 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              1
            </div>
            <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
              <div className="">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Перезавантажити термінал
                </h2>
                <p className="leading-relaxed">
                для стаціонарного терміналу – від’єднати кабель живлення терміналу, для переносного залежно від моделі: Castles – натиснути та утримувати жовту та маленьку точку. Для Verifone – натиснути та утримувати червону кнопку, попередньо відключивши від зарядного пристрою.
                </p>
              </div>
            </div>
          </div>
         
          {/* крок 2 */}
          <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              2
            </div>
            <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
              <div className="mt-6">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Переконатися, що на терміналі висвічується фраза "Tampered
                  Device" або "Alert Irruption".
                </h2>
                <p className="leading-relaxed"></p>
              </div>
            </div>
          </div>
       
          {/* крок 3 */}
          <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
            <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
              <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
            </div>
            {/* номер кроку */}
            <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
              3
            </div>
            <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
              <div className="mt-6">
                <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                  Якщо пункти вище не допомогли, натисніть внизу кнопку "Нічого
                  не допомогло".
                </h2>
                <p className="leading-relaxed"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  
  // Taska 3 - Не проводить оплати
  if (orderTask === "Не проводить оплати") 
  return(
    <section className="text-gray-600 body-font">
      <div className="container flex flex-wrap px-5 py-4 mx-auto">
        {/* крок 1 */}
        <div className="relative flex flex-grow pt-0 pb-0 mt-4 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            1
          </div>
          <div className="flex flex-col items-start flex-grow pl-6 md:pl-8 sm:items-center sm:flex-row">
            <div className="">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                Перезавантажити термінал
              </h2>
              <p className="leading-relaxed">
              для стаціонарного терміналу – від’єднати кабель живлення терміналу, для переносного залежно від моделі: Castles – натиснути та утримувати жовту та маленьку точку. Для Verifone – натиснути та утримувати червону кнопку, попередньо відключивши від зарядного пристрою.
              </p>
            </div>
          </div>
        </div>
        {/* крок 2 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            2
          </div>
          <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                Після перезавантаження зробити тестову транзакцію.
              </h2>
              <p className="leading-relaxed"></p>
            </div>
          </div>
        </div>
        {/* крок 3 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            3
          </div>
          <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                Закрити день на терміналі (z-звіт).
              </h2>
              <p className="leading-relaxed"></p>
            </div>
          </div>
        </div>

        {/* крок 4 */}
        <div className="relative flex flex-grow pt-0 pb-0 sm:items-center md:w-2/3">
          <div className="absolute inset-0 flex items-center justify-center w-6 h-full">
            <div className="w-1 h-full bg-gray-200 pointer-events-none"></div>
          </div>
          {/* номер кроку */}
          <div className="relative z-10 inline-flex items-center justify-center flex-shrink-0 w-6 h-6 mt-10 text-sm font-medium text-white bg-indigo-500 rounded-full sm:mt-0 title-font">
            4
          </div>
          <div className="flex flex-col items-start flex-grow md:pl-8 sm:items-center sm:flex-row">
            <div className="mt-6">
              <h2 className="mb-1 text-xl font-medium text-gray-900 title-font">
                Якщо пункти вище не допомогли, натисніть внизу кнопку "Нічого не
                допомогло".
              </h2>
              <p className="leading-relaxed"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
  
};
export default Taska1;
