import React, { useState, useEffect } from "react";
import axios from "axios";
import BanerAlert from "../ui/BanerAlert";


export default function Terminal(props) {

  const { data, golvlCreateOrder, goStart, info, setInfo, golvlEditTerminalInfo} = props;
  // const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [btnEdit, setBtnEdit] = useState("Редагувати")


  // const edit = () => {
  //   setDisabled(disabled => !disabled);
  //   save()
  // }
  //   const save = () => {
  // if(btnEdit === "Редагувати") {
  //   setBtnEdit('Зберегти')
  // }else(setBtnEdit("Редагувати"))
  // };


  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await axios
      .post("/info", {
        serial: `${data.serial}`,
        bankID: `${data.bankID}`
      })
      .then((response) => {
        if ('client' in response.data) {
          let resData = response.data;
          console.log("client" in response.data);
          setInfo(resData);
           console.log(resData);
        return resData;
        } else {
          setLoading(false);
          setError(true);
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        console.error("Помилка при завантажені : ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading)
    return (
      <div>
        <h1>Завантачення даних терміналу....</h1>
      </div>
    );
    
  if (error)
    return (
      <div>
        <h1 className="text-2xl font-extrabold text-center text-red-600">
          Сталася помилка
        </h1>
        <div>
          Вибачте, але <b>термінала з таким ID та серійним номером немає в базі</b>
        </div>
        <div className="">
          Можливо, ви некорректно написали номер, або ж вам потрібно звернутися
          за номером техпідтримки.
        </div>
        <button className="btn-red" onClick={goStart}>
          На початок
        </button>
      </div>
    );
   
  return (
    <>
      <div className="m-2">
        <BanerAlert />

{/* зона формы */}
<div>
<p className="text-md"><b>Назва локації:</b> {info.location.locationName}</p>
<p className="text-md"><b>Модель терміналу:</b> {info.terminal.model}</p>
<p className="text-md"><b>Банк клієнта: </b> {info.client.clientName}</p>
<p className="text-md"><b>Контактна особа:</b> {info.locationAddress.locationContactName}</p>
<p className="text-md"><b>Номер контактної особи:</b> {info.locationAddress.locationContactPhone}</p>
</div>

      </div>
{/* зона кнопок */}
      <div className="space-y-2">
     
      <button
        onClick={golvlEditTerminalInfo}
        // className="items-center justify-center w-full h-12 px-6 m-2 font-medium tracking-wide text-white transition duration-200 bg-red-600 rounded-lg hover:bg-red-800 focus:shadow-outline focus:outline-none"
        className="btn-red"
      >
        Редагувати 
      </button>

      <button
        onClick={golvlCreateOrder}
        // className="items-center justify-center w-full h-12 px-6 pt-2 m-2 font-medium tracking-wide text-white transition duration-200 bg-green-700 rounded-lg hover:bg-green-800 focus:shadow-outline focus:outline-none"
        className="btn-raif"
      >
        Все добре! Продовжуєм.
      </button>
      </div>
    </>
  );
}
