import React from "react";
import Header from "../ui/Header";

export default function Start(props) {
    
    const{goNextLevel} = props

  return (
    <>
      <Header goNextLevel={goNextLevel}/>
      
    </>
  );
}
