import React, { useEffect } from "react";

export default function CreateOrder(props) {

  useEffect(() => {
    document.title = `Сторінка замовлення`;  
  }, []);

  const {
    goPaper,
    goService,
    goInitTerminal,
    goBackLevel
    } = props;

  return (
    <>
      <div className="w-full p-4 font-light text-gray-600 bg-white text-md">
        <p>Виберіть потрібну дію</p>
        
        {/* кпопки вибору jobType */}
        <div>
          <ul>
            <button onClick={goPaper} className="btn-servise" id="paper-order">
              🧾 Замовлення паперу
            </button>
            <button onClick={goService} className="btn-servise" id="out-of-order">
              🆘 Не працює термінал
            </button>
            <a href="tel:0800501359" className="btn-servise btn-raif" id="call">
              📞 Зв'язатися з диспетчером
            </a>
            <button onClick={goInitTerminal} className="btn-servise" id="terminal-init">
              📲 Ініціалізація терміналу
            </button>
          </ul>
          <button onClick={goBackLevel} className="btn-servise">
                👈 Крок назад
          </button>
        </div>
      </div>

    </>
  );
}
